<template>
  <div class="container">
    <br>
    <el-table
      :key="tableKey"
      v-loading="loading"
      :data="list"
    >
      <el-table-column
        type="index"
        width="50"
      />
      <el-table-column
        :label="$t('body.news.title')"
      >
        <template slot-scope="scope">
          <span v-if="locale === 'en'">{{ scope.row.titleEn }}</span>
          <span v-else>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('body.news.issueDate')"
        width="200px"
      >
        <template slot-scope="scope">
          {{ scope.row.issueDate | datetimeFilter }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('body.news.detail')"
        width="100px"
      >
        <template slot-scope="scope">
          <router-link :to="{ path: '/news/news-detail?id=' + scope.row.id + '&type=' + type }">{{ $t('body.news.detail') }}</router-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getNews } from '@/api/csp'
import Pagination from '@/components/Pagination'
export default {
  name: 'NewsList',
  components: { Pagination },
  data() {
    return {
      type: 'industry',
      list: [],
      tableKey: 0,
      total: 0,
      loading: false,
      listQuery: {
        page: 1,
        limit: 20,
        status: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'locale',
      'name',
      'sidebar',
      'avatar',
      'device'
    ])
  },
  mounted() {
    this.type = this.$route.query.type
    this.loadData()
  },
  methods: {
    loadData() {
      getNews({
        type: this.type,
        pageNum: this.listQuery.page,
        pageSize: this.listQuery.limit
      }).then(resp => {
        this.list = resp.list
        this.total = resp.total
        this.loading = false
      })
    },
    pagination() {
      this.listQuery.status = this.status
      this.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
  .blogpost-body {
    .blogpost-content {
      .content {
      }
    }
  }
</style>
